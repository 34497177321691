.random-project__project__bad-password-generator {
  text-align: center;
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;

  margin: 32px;

  #changing-passwd {
    font-weight: bold;
    font-size: 5em;
    text-transform: capitalize;
  }

  .text {
    margin-top: -64px;
  }
}

@media screen and (max-width: 768px) {
  .random-project__project__bad-password-generator {
    #changing-passwd {
      font-size: 3em;
    }
  }
}

@media screen and (max-width: 320px) {
  .random-project__project__bad-password-generator {
    #changing-passwd {
      font-size: 2em;
    }
  }
}
