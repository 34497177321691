$page-width: 900px;
$logo-width: 150px;
$logo-width-small: 100px;
$page-padding-between: 40px;

:root {
  --color-background: #1e1e1e;
  --color-block: #282828;
  --color-text: white;
  --color-link: lightgreen;
  --color-link-hover-bg: #3e3e3e;
  --color-code: pink;
  --color-pre-code-bg: black;
  --color-quote: lightblue;
  --color-aside: #757575;
  --color-success: mediumspringgreen;
  --color-failure: #ee4444;
}

@media (prefers-color-scheme: light) {
  :root {
    --color-background: white;
    --color-block: #f8f8f8;
    --color-text: #212121;
    --color-link: #1a0dab;
    --color-link-hover-bg: #ececec;
    --color-code: #ff4444;
    --color-pre-code-bg: #f8f8f8;
    --color-quote: purple;
    --color-aside: #757575;
    --color-success: forestgreen;
    --color-failure: #db1414;
  }
}

@import 'normalize';
@import 'fonts';
@import 'header';
@import 'markdown';
@import 'responsive';
@import 'random-project';
@import 'credly-widget';
@import 'highlight';

* {
  box-sizing: border-box;
}

body {
  font-family: 'Roboto Mono', monospace;
  margin: 16px;
  background-color: var(--color-background);
  color: var(--color-text);
  word-wrap: break-word;
}

.page main, footer {
  padding-left: $logo-width + $page-padding-between;
  max-width: $page-width;
  margin: 0 auto;
}

.page main {
  margin-top: 32px;
  line-height: 1.3;
}

footer {
  color: #757575;
  font-style: italic;
  padding-top: 16px;
  padding-bottom: 16px;

  a {
    color: inherit;
  }

  &:hover {
    a {
      color: var(--color-link);
    }
  }
}

a {
  color: var(--color-link);

  &:hover, &:focus {
    background-color: var(--color-link-hover-bg);
  }
}

video {
  width: 100%;
}

img.center {
  display: block;
  margin: 0 auto;
}

.grow {
  flex-grow: 1;
}

.date {
  color: grey;

  &::before {
    content: '[';
  }
  &::after {
    content: ']';
  }
}

.notice {
  background-color: var(--color-block);
  padding: 16px;
}

details.warning {
  background-color: var(--color-block);
  padding: 16px;
  border-left: 8px solid var(--color-failure);

  summary {
    cursor: pointer;
  }
}

details.info {
  background-color: var(--color-block);
  padding: 16px;
  border-left: 8px solid var(--color-quote);

  summary {
    cursor: pointer;
  }
}

a.block-link {
  display: block;
  border: 1px var(--color-aside) solid;
  margin-bottom: 16px;
  padding: 16px;
  text-decoration: none;
  background-color: var(--color-block);

  &:hover, &:focus {
    background-color: var(--color-link-hover-bg);
  }
}