@media screen and (max-width: 750px) {
  main, footer {
    padding-left: 0 !important;
  }

  .dotted-line {
    display: none;
  }

  header {
    .logo {
      width: $logo-width-small;
      height: $logo-width-small;
    }

    .title {
      padding-left: 140px;
    }

    .logo-container {
      position: absolute;
      height: unset;
    }
  }
}
