header {
  display: flex;
  max-width: $page-width;
  margin: 0 auto;

  .logo-container {
    max-width: $page-width;
    height: 100%;
    margin: 0 auto;
    position: fixed;
    display: flex;
    flex-direction: column;

    .dotted-line {
      border-style: dotted;
      border-image-source: url(../assets/dots.svg);
      border-image-slice: 33% 33%;
      border-image-repeat: round;
      border-width: 0  8px 0 0;
      margin-top: 32px;
      width: calc(50% + 4px);
      flex-grow: 1;
    }

    a:hover {
      background-color: inherit;
    }
  }

  .logo {
    width: $logo-width;
    height: $logo-width;
    border-radius: 50%;
  }

  .title {
    padding-left: $logo-width + $page-padding-between;
    padding-top: ($logo-width - 45px) / 2;;
    padding-bottom: ($logo-width - 45px) / 2;
    display: flex;
    flex-direction: column;

    .name {
      font-weight: bold;
      font-size: 1.5em;
    }
  }

  .menu {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      // Prevent newlines from being turned into spaces.
      font-size: 0;
    }

    li {
      // Don't inherit the no spaces hack from ul.
      font-size: 1rem;

      display: inline;
      padding-left: 0px;
    }

    a {
      text-decoration: none;
    }

    .trailing-comma {
      display: none;
    }

    .comma::after {
      // add one space after each comma to be complient with gofmt.
      content: " ";
    }
  }
}

@media screen and (max-width: 900px) {
  header {
    .menu {
      li {
        display: list-item;
        padding-left: 20px;
        &:first-of-type, &:last-of-type {
          padding-left: 0;
        }
      }

      .trailing-comma {
        display: initial;
      }
    }
    .title {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }
}
